.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.forgot-password-container {
  width: auto;
  margin: 1rem;
  margin-top: 2rem;
}

.paper-wrapper {
  padding: 1rem;
  margin: auto;
  max-width: 500px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-center-collumn {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.fill-width {
  width: 100%;
}

.dayselector {
  align-items: center;
  margin-top: 15px;
  display: flex;
}

.margin-left {
  width: 90px;
}

.picker {
  margin-left: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.file-input-container {
  border: 1px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: height 0.2 ease;
  margin-top: 5px;
}

.drag-active {
  border-color: #78e5d5;
}

.drag-reject {
  border-color: #e57878;
}

.preview-image {
  height: 120px;
  width: 120px;
  padding: 15px;
  border-radius: 5px;
}

.preview-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.rbc-event-content {
  font-size: 0.75rem;
}
